import { Link } from 'gatsby'
import React from 'react'
import './style.scss'
import { StaticQuery, graphql } from 'gatsby'

const Footer = ({ data }) => (
  <footer id="footer" className="text-gray">
    <div className="container">
      <div className="row justify-content-center align-items-center">
        <div className="col-md-12 text-center">
          <ul className="socials">
            <li>
              <a
                href={data.markdownRemark.frontmatter.facebook}
                className="icon-facebook fs-20"
              />
            </li>
            <li>
              <a
                href={data.markdownRemark.frontmatter.instagram}
                className="icon-instagram fs-20"
              />
            </li>
            {/* <li>
              <a
                href={data.markdownRemark.frontmatter.twitter}
                className="icon-twitter fs-20"
              />
            </li>
            <li>
              <a
                href={data.markdownRemark.frontmatter.linkedin}
                className="icon-linkedin fs-20"
              /> */}
            {/* </li> */}
          </ul>
        </div>
      </div>
    </div>
  </footer>
)

export default props => (
  <StaticQuery
    query={graphql`
      query {
        markdownRemark(frontmatter: { category: { eq: "footer" } }) {
          id
          frontmatter {
            category
            title
            facebook
            twitter
            linkedin
            instagram
            phone
          }
        }
      }
    `}
    render={data => <Footer data={data} {...props} />}
  />
)
